import { Controller } from "@hotwired/stimulus"
import { throttle } from "../utils/helpers"
export default class extends Controller {
  static targets = ['checkpoint', 'autosubmit', 'submit']

  connect() {
    this.bindInputEvents()
  }

  checkSubmit() {
    for(const element of this.checkpointTargets) {
      if ((element.nodeName == 'INPUT' && element.type == 'checkbox' && !element.checked)
      || (element.nodeName == 'INPUT' && !element.value)
      || (element.nodeName == 'SELECT' && !element.value)
      || (element.nodeName == 'DIV' && !element.dataset.checked)) {
        this.submitTarget.disabled = true
        return
      }
    }

    this.submitTarget.disabled = false
  }

  submitEnabledValueChanged(value, previous) {
    if (previous == undefined) return

    this.submitTarget.disabled = !this.submitEnabledValue
  }

  stayDisabled(event) {
    event.target.querySelectorAll('[type=submit]').forEach((button) => {
      button.disabled = true
    })
  }

  disableForm() {
    this.submitTarget.disabled = true
  }

  enableForm() {
    this.submitTarget.disabled = false
  }

  hideError() {
    const parent = this.closest('.form-field')
    if (parent) { parent.classList.remove('invalid') }
  }

  autosubmit() {
    const maxLength = this.autosubmitTarget.dataset.length
    const length = this.autosubmitTarget.value.length

    if (length == maxLength) this.element.submit()
  }

  bindInputEvents() {
    this.element.addEventListener('turbo:submit-end', this.stayDisabled.bind(this))
    if (this.hasAutosubmitTarget) this.autosubmitTarget.addEventListener('keyup', this.autosubmit.bind(this))

    Array.from(this.element.querySelectorAll('input')).forEach(el => el.addEventListener('focus', throttle(this.hideError)))
  }
}
