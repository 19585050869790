import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = { url: String, selected: String }

  connect() {
    const data = this.dataFromOptions()

    this.slim = new SlimSelect({
      select: this.element,
      data: this.placeholder().concat(data),
      settings: {
        showSearch: this.element.querySelectorAll('option').length > 10,
        searchPlaceholder: 'Поиск',
        searchText: 'Нет результатов',
        searchingText: 'Поиск...',
        searchHighlight: false,
        showContent: 'down',
        valuesUseText: false,
        placeholderText: '',
        disabled: data.length == 0
      }
    })

    setTimeout( () => {
      this.element.dispatchEvent(new CustomEvent('slim:added'))
    }, 1)
  }

  placeholder() {
    return [{ placeholder: true, text: '' }]
  }

  dataFromOptions() {
    return this.element.querySelectorAll('optgroup').length > 0
      ? Array.from(this.element.querySelectorAll('optgroup')).map (opt => (
        {
          label: opt.getAttribute('label'),
          options: Array.from(opt.querySelectorAll('option')).map ( option => this.optionHTML(option))
        }
      ))
      : Array.from(this.element.querySelectorAll('option')).map (option => this.optionHTML(option))
  }

  optionHTML(option) {
    return {
      html: `<div class="flex items-baseline justify-between w-full"><span class="main mr-2">${option.textContent}</span><span class="info">${option.dataset.info || ''}</span></div>`,
      text: `${option.textContent} ${option.dataset.info || ''}`,
      value: option.getAttribute('value'),
      data: option.dataset,
      selected: this.selectedValue == option.getAttribute('value')
    }
  }

  fetch() {
    if (!this.element.value) return

    let url = new URL(this.urlValue)
    url.searchParams.append('obj_id', this.element.value)

    window.showLoader()

    fetch(url.href, {
      headers: { accept: 'text/vnd.turbo-stream.html' }
    }).then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
