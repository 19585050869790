import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";
export default class extends Controller {
  static values = {
    mask: { type: String, default: ''},
    placeholder: { type: String, default: '-' },
    alias: { type: String, default: 'numeric' }
  }

  connect() {
    this.element[this.identifier] = this
    var params = {}
    if (this.aliasValue == 'birthDate') {
      params = {
        alias: 'datetime',
        placeholder: "дд.мм.гггг",
        inputFormat: "dd.mm.yyyy",
        min: '01.01.1900',
        max: new Date().toISOString().slice(0,10).split('-').reverse('').join('.'),
      }
    } else if (this.aliasValue == 'email') {
      params = {
        regex: "^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
      }
    } else {
      params = {
        placeholder: this.placeholderValue,
        mask: this.maskValue,
      }
    }
    this.inputmask = Inputmask(params)
    this.inputmask.mask(this.element);
  }

  isValid() {
    return this.inputmask.isValid(this.element.value)
  }
}
