import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hidden-field"
export default class extends Controller {
  static targets = ['select', 'hidden']
  static values = {
    type: String
  }

  connect() {
    console.log('test')
  }

  updateHiddenField() {
    if (this.selectTarget.options){
      var selected = this.selectTarget.options[this.selectTarget.selectedIndex]
      this.hiddenTargets.forEach((field) => {
        var type = field.dataset.type
        field.value = selected.getAttribute('data-' + type)
      })
    }
  }
}
