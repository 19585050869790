import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    content: { type: String, default: ''},
  }

  connect() {
    this.tooltip = tippy(this.element, {
      content: this.contentValue,
      maxWidth: 220,
    })
  }
}
