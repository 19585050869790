import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['watch', 'link']

  static values = {
    max: { type: Number, default: 120 },
    start: String,
    running: Boolean
  }

  get timePassed() {
    return Date.now() - this.startTime
  }

  connect() {
    this.hiddenClass = 'hidden'
    this.startTime = Date.parse(this.startValue)
    this.timer = setInterval(this.updateTimer.bind(this), 100)
  }

  updateTimer() {
    const interval = this.timePassed / 1000

    if (interval < this.maxValue) {
      this.watchTarget.querySelector('span').textContent = Math.round(this.maxValue - interval)
      this.runningValue = true
    } else {
      clearInterval(this.timer)
      this.runningValue = false
    }
  }

  runningValueChanged(_current, prev) {
    if (prev == undefined) return

    if (this.runningValue) {
      this.linkTarget.classList.add(this.hiddenClass)
      this.watchTarget.classList.remove(this.hiddenClass)
    } else {
      this.linkTarget.classList.remove(this.hiddenClass)
      this.watchTarget.classList.add(this.hiddenClass)
    }
  }
}
