import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static values = {
    url: String,
    animation: { type: String, default: 'fade' },
    hidden: { type: Boolean, default: false }
  }

  connect() {
    this.element[this.identifier] = this

    if (!this.hiddenValue) this.show()
  }

  show() {
    enter(this.element, this.animationValue).then(() => {
      this.lock()
      this.element.dispatchEvent(new CustomEvent('modal:opened', { bubbles: true }))
    })
  }

  close() {
    leave(this.element, this.animationValue).then(() => {
      this.unlock()
      this.element.dispatchEvent(new CustomEvent('modal:closed', { bubbles: true }))
      this.redirect()
    })
  }

  closeWithEsc(e) {
    if (e.code == 'Escape') {
      this.close()
    }
  }

  closeWithBackground(e) {
    if (e.target == this.element) {
      this.close()
    }
  }

  lock() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    this.element.style.paddingRight = `${scrollbarWidth}px`
    document.body.style.paddingRight = `${scrollbarWidth}px`
    document.body.classList.add('overflow-hidden')

    window.addEventListener('keyup', this.closeWithEsc.bind(this))
    this.element.addEventListener('click', this.closeWithBackground.bind(this))
  }

  unlock() {
    this.element.style.paddingRight = 0
    document.body.style.paddingRight = 0
    document.body.classList.remove('overflow-hidden')

    window.removeEventListener('keyup', this.closeWithEsc.bind(this))
    this.element.removeEventListener('click', this.closeWithBackground.bind(this))
  }

  redirect() {
    if (this.urlValue) {
      window.history.replaceState(null, '', '/')
      window.location.href = this.urlValue
    }
  }
}
