import { Controller } from "@hotwired/stimulus"
import AirDatepicker from 'air-datepicker'
import localeRu from 'air-datepicker/locale/ru'

export default class extends Controller {
  connect() {
    this.datepicker = new AirDatepicker(this.element, {
      minDate: '01.01.1900',
      maxDate: new Date(),
      locale: localeRu,
      dateFormat: "dd.MM.yyyy",
      autoClose: false,
      onSelect: ({ formattedDate }) => {
        this.element.value = formattedDate;
      }
    })
    this.element.addEventListener('input', this.updateCalendarView.bind(this))
  }

  updateCalendarView(event) {
    const inputValue = event.target.value;
    const parsedDate = this.parseDate(inputValue, "dd.MM.yyyy");

    if (parsedDate) {
      this.datepicker.setViewDate(parsedDate);

      if (this.isFullDate(inputValue)) {
        this.datepicker.selectDate(parsedDate);
        this.datepicker.show();
      }
    }
  }

  isFullDate(dateString) {
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
    return datePattern.test(dateString);
  }

  parseDate(dateString, format) {
    const [day, month, year] = dateString.split(".");
    const date = new Date;

    if (day && parseInt(day, 10) > 0) {
      date.setDate(parseInt(day, 10));
    }
    
    if (month && parseInt(month, 10) > 0) {
      date.setMonth(parseInt(month, 10) - 1);
    }
    
    if (year && parseInt(year, 10) > 0) {
      date.setFullYear(parseInt(year, 10));
    }
  
    return date;
  }
}
