import Dropzone from "dropzone"
import { Controller } from "@hotwired/stimulus"
import DirectUploadController from "../utils/direct_uploader"
import { getMetaValue } from '../utils/helpers'

export default class extends Controller {
  static targets = ['container', 'input', 'trigger', 'error']
  static classes = ['error']
  static values = {
    maxFilesize: { type: Number, default: 10 },
    maxFiles: { type: Number, default: 1 },
    acceptedFiles: { type: String, default: '' },
    addRemoveLinks: { type: Boolean, default: true },
    clickable: { type: Boolean, default: true }
  }

  connect() {
    Dropzone.autoDiscover = false // necessary quirk for Dropzone error in console

    this.dropZone = new Dropzone(this.containerTarget, {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFilesValue,
      maxFilesize: this.maxFilesizeValue,
      acceptedFiles: this.acceptedFilesValue,
      addRemoveLinks: this.addRemoveLinksValue,
      clickable: this.hasTriggerTarget ? this.triggerTarget : this.clickableValue,
      autoQueue: false,
      dictFallbackMessage: 'Ваш браузер не поддерживает drag-n-drop',
      dictFileTooBig: 'Превышен допустимый размер файла: {{maxFilesize}}MB',
      dictInvalidFileType: 'Это недопустимый формат файла',
      dictResponseError: 'Сервер ответил с кодом {{statusCode}}',
      dictCancelUpload: 'Отменить',
      dictCancelUploadConfirmation: 'Вы уверены, что хотите отменить загрузку?',
      dictRemoveFile: 'Удалить файл',
      dictMaxFilesExceeded: 'Вы не можете загрузить больше файлов'
    })

    this.bindEvents()
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') }
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }

  bindEvents() {
    this.dropZone.on('addedfile', file => {
      this.removeError()

      setTimeout(() => {
        file.accepted && new DirectUploadController(this, file).start()
      }, 500)
    })

    this.dropZone.on('removedfile', file => {
      file.controller && file.controller.removeFile()
      this.removeError()
    })

    this.dropZone.on('canceled', file => {
      file.controller && file.controller.cancelFile()
      this.removeError()
    })

    this.dropZone.on('error', (file, error) => {
      this.dropZone.removeFile(file)
      this.showError(error)
    })
  }

  showError(message) {
    this.errorTarget.textContent = message
    this.element.classList.add(this.errorClass)
  }

  removeError() {
    this.element.classList.remove(this.errorClass)
    this.errorTarget.textContent = ''
  }
}
