import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['month', 'day', 'time', 'slots', 'input']
  static classes = ['active']
  static values = {
    url: String,
    dateTime: { type: String, default: '' },
    dayIndex: { type: Number, default: 0 },
    timeIndex: { type: Number, default: 0 },
  }

  NOINDEX = -1
  MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

  get currentDay() { return this.dayTargets[this.dayIndexValue].dataset.value }
  get currentTime() { return this.timeTargets[this.timeIndexValue].dataset.value }
  get currentMonth() { return  this.MONTHS[new Date(this.currentDay).getMonth()] }

  setCurrentDay(event) {
    this.dayIndexValue = this.dayTargets.findIndex(item => item === event.currentTarget)
  }

  setCurrentTime(event) {
    this.timeIndexValue = this.timeTargets.findIndex(item => item === event.currentTarget)
  }

  setCurrentDateTime() {
    this.dateTimeValue = this.timeIndexValue != this.NOINDEX
      ? `${this.currentTime}`
      : ''
  }

  dayIndexValueChanged(index, prevIndex) {
    this.chooseCurrentTarget(this.dayTargets, prevIndex, index)
    this.timeIndexValue = this.NOINDEX
    this.handleMonth()

    this.fetchTimes()
  }

  timeIndexValueChanged(index, prevIndex) {
    if (!this.hasTimeTarget) return

    this.chooseCurrentTarget(this.timeTargets, prevIndex, index)
    this.setCurrentDateTime()
  }

  dateTimeValueChanged() {
    this.inputTarget.value = this.dateTimeValue
    this.element.dispatchEvent(new CustomEvent('schedule:changed', { bubbles: true }))
  }

  chooseCurrentTarget(targets, prevIndex, index) {
    if (prevIndex != undefined && prevIndex != this.NOINDEX) {
      targets[prevIndex].classList.remove(...this.activeClasses)
    }
    if (index != this.NOINDEX) {
      targets[index].classList.add(...this.activeClasses)
    }
  }

  handleMonth() {
    this.monthTarget.textContent = this.currentMonth
  }

  fetchTimes() {
    let url = new URL(this.urlValue)
    url.searchParams.append('date', this.currentDay)

    window.showLoader()

    fetch(url.href, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
