import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['icon']
  static classes = ['hidden']

  connect() {
    this.inputTarget = this.element.querySelector('input')
    this.toggleFunction = this.toggle.bind(this)
  }

  get readonly() {
    return this.inputTarget.readOnly
  }

  set readonly(readonly) {
    this.inputTarget.readOnly = readonly
  }

  toggle() {
    this.readonly = !this.readonly
    this.iconTarget.classList.toggle(this.hiddenClass)

    if (!this.readonly) {
      this.inputTarget.focus()
      this.inputTarget.addEventListener('blur', this.toggleFunction)

    } else {
      this.inputTarget.removeEventListener('blur', this.toggleFunction)
    }
  }
}
