import { Controller } from '@hotwired/stimulus'
import { enter, leave, toggle } from 'el-transition'

export default class extends Controller {
  static targets = ['button', 'body']
  static values = {
    animation: { type: String, default: 'dropdown' },
    opened: { type: Boolean, default: false }
  }
  static classes = ['opened']

  connect() {
    this.bindAction()
    if (this.openedValue) { this.showDropdown(false) }
  }

  bindAction() {
    this.buttonTarget.addEventListener('click', this.toggleDropdown.bind(this))
  }

  toggleDropdown(animate = true) {
    toggle(this.bodyTarget, this.animation(animate))
    this.openedValue = !this.openedValue
  }

  showDropdown(animate = true) {
    enter(this.bodyTarget, this.animation(animate))
    this.openedValue = true
  }

  hideDropdown(animate = true) {
    leave(this.bodyTarget, this.animation(animate))
    this.openedValue = false
  }

  animation(animate = true) {
    return animate ? this.animationValue : null
  }

  openedValueChanged() {
    this.openedValue ? this.buttonTarget.classList.add(this.openedClass) : this.buttonTarget.classList.remove(this.openedClass)
  }
}
