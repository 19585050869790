// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

Turbo.setConfirmMethod((message, _element) => {
  const dialog = document.getElementById('turbo-confirm')
  const modal = dialog.querySelector('[data-controller="modal"]')

  modal.querySelector('p').textContent = message

  dialog.showModal()
  modal.modal.show()

  dialog.addEventListener('modal:closed', () => {
    dialog.close('cancel')
  })

  return new Promise((resolve, _reject) => {
    dialog.addEventListener('close', () => {
      modal.modal.close()
      resolve(dialog.returnValue == 'confirm')
    }, { once: true })
  })
})


window.showLoader = () => {
  document.getElementById('loader').classList.remove('hidden')
}

window.hideLoader = () => {
  document.getElementById('loader').classList.add('hidden')
}

// window.addEventListener('turbo:before-fetch-request', showLoader)
window.addEventListener('turbo:before-stream-render', hideLoader)
window.addEventListener('turbo:before-fetch-response', hideLoader)
