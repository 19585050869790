import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static values = {
    animation: { type: String, default: 'fade' }
  }

  connect() {
    this.show()
  }

  show() {
    setTimeout(() => {
      enter(this.element, this.animationValue).then(() => {
        setTimeout(() => this.close(), 3000)
      })
    }, 500)
  }

  close() {
    leave(this.element, this.animationValue)
  }
}
