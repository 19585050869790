import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = [ 'modal' ]

  show() {
    if (this.hasModalOutlet) { this.modalOutlet.show() }
  }

  close() {
    if (this.hasModalOutlet) { this.modalOutlet.close() }
  }
}
