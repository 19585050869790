import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {  siteKey: String }

  connect() {
    this.initRecaptcha()
  }

  initRecaptcha() {
    setTimeout(()=> {
      if (typeof grecaptcha == 'undefined' || typeof grecaptcha.render == 'undefined') {
        console.log('Recaptcha not loaded')
        this.initRecaptcha()
      } else {
        grecaptcha.render('recaptcha', {
          sitekey: this.siteKeyValue,
          callback: this.check.bind(this)
        })
      }
    }, 100)
  }

  check() {
    this.element.dataset.checked = true
    this.element.dispatchEvent(new CustomEvent('recaptcha:changed', { bubbles: true }))
  }
}
