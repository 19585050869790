import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'icon']
  static classes = ['hidden']

  get inputType() {
    return this.inputTarget.getAttribute('type')
  }

  set inputType(type) {
    this.inputTarget.setAttribute('type', type)
  }

  toggle() {
    //this.inputType = this.inputType == 'text' ? 'password' : 'text'
    this.inputTarget.classList.toggle('password')
    this.iconTargets.forEach((icon) => icon.classList.toggle(this.hiddenClass))
  }

  check() {
    var formField = this.inputTarget.closest('.form-field')
    var error = formField.querySelector('.form-error')
    if (document.querySelector('#user_password').value == this.inputTarget.value) {
      formField.classList.remove('invalid')
      error.textContent = ''
    } else {
      formField.classList.add('invalid')
      error.textContent= 'Некорректный формат'
    }
  }
}
