import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

export default class extends Controller {
  static values = {
    type: { type: String, default: 'slide' },
    perPage: { type: Number, default: 1 },
    perMove: { type: Number, default: 1 },
    pagination: { type: Boolean, default: true },
    arrows: { type: Boolean, default: true },
    fixedWidth: { type: String, default: '300px' },
    gap: { type: String, default: '15px' }
  }

  connect() {
    new Splide(this.element, {
      type: this.typeValue,
      perPage: this.perPageValue,
      perMove: this.perMoveValue,
      pagination: this.paginationValue,
      arrows: this.arrowsValue,
      fixedWidth: this.fixedWidthValue,
      gap: this.gapValue
    }).mount()
  }
}
